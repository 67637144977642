var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "warehouse-optimization-status-changer" },
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "cs-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            small: "",
                            color:
                              _vm.selectedStatusItem?.color ?? "transparent",
                            loading: !_vm.selectedStatusItem,
                            dark: "",
                          },
                        },
                        "cs-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm.loading
                        ? _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: 18,
                              color: "primary",
                            },
                          })
                        : _vm._e(),
                      _vm._v(
                        " " + _vm._s(_vm.$t(_vm.selectedStatusItem?.text)) + " "
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { value: _vm.selectedStatus, color: "primary" },
                  on: { input: _vm.onStatusChange },
                },
                _vm._l(_vm.statusMenuItems, function (item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      attrs: {
                        disabled: _vm.computeDisabled(item),
                        value: item.value,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onStatusChange(item)
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "status-circle ma-2",
                        style: { background: item.color },
                      }),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(_vm.$t(item.text))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }