
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { modelViewerStore } from '@/store/modelViewer.store'
import { Get, NodalLoad } from '@consteel/storm'
import Vue from 'vue'
import InfoTable from './InfoTable.vue'
import { TableNode } from './TableNode'

export default Vue.extend({
  name: 'NodalLoadTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryMember(): NodalLoad | null {
      if (!modelViewerStore.model.rawSmadsteelModel) return null

      return Get<NodalLoad>(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        NodalLoad
      )
    },
    secondaryMember(): NodalLoad | null {
      if (!this.compare || !modelViewerStore.secondaryModel.rawSmadsteelModel) return null
      return Get<NodalLoad>(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        NodalLoad
      )
    },

    primaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.primaryMember)
    },

    secondaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.secondaryMember)
    },
  },
  methods: {
    multiplicationFactor(member: NodalLoad): number {
      return member?.multiplicationFactor || 1
    },
    getTableItemsFromModelItem(item: NodalLoad | null): TableNode[] {
      if (!item) return []

      return [
        {
          name: this.$t('Name'),
          value: item.name,
        },
        {
          name: this.$t('Loadcase name'),
          value: item.loadCase?.name,
        },
        {
          name: this.$t('X'),
          value: roundToTwoDigits(item.force ? item.force.x * this.multiplicationFactor(item) : 0),
          unit: latex_units.kn,
        },
        {
          name: this.$t('Y'),
          value: roundToTwoDigits(item.force ? item.force.y * this.multiplicationFactor(item) : 0),
          unit: latex_units.kn,
        },
        {
          name: this.$t('Z'),
          value: roundToTwoDigits(item.force ? item.force.z * this.multiplicationFactor(item) : 0),
          unit: latex_units.kn,
        },
        {
          name: this.$t('Mx'),
          value: roundToTwoDigits(
            item.moment ? item.moment.xx * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.knm,
        },
        {
          name: this.$t('My'),
          value: roundToTwoDigits(
            item.moment ? item.moment.yy * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.knm,
        },
        {
          name: this.$t('Mz'),
          value: roundToTwoDigits(
            item.moment ? item.moment.zz * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.knm,
        },
        {
          name: this.$t('Eccentricity reference'),
          value: item.eccentricity?.reference,
        },
        {
          name: this.$t('Eccentricity - y'),
          value: roundToTwoDigits(item.eccentricity?.y),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Eccentricity - z'),
          value: roundToTwoDigits(item.eccentricity?.z),
          unit: latex_units.mm,
        },
      ]
    },
  },
})
