
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { modelViewerStore } from '@/store/modelViewer.store'
import { Get, NodalSupport } from '@consteel/storm'
import { Dof } from '@consteel/storm/src/Models/Release/Dof'
import Vue from 'vue'
import { TableNode } from './TableNode'
import InfoTable from './InfoTable.vue'

export default Vue.extend({
  name: 'SupportPointTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryMember(): NodalSupport | null {
      if (!modelViewerStore.model.rawSmadsteelModel) return null

      return Get<NodalSupport>(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        NodalSupport
      )
    },
    secondaryMember(): NodalSupport | null {
      if (!this.compare || !modelViewerStore.secondaryModel.rawSmadsteelModel) return null
      return Get<NodalSupport>(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        NodalSupport
      )
    },

    primaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.primaryMember)
    },

    secondaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.secondaryMember)
    },
  },
  methods: {
    getTableItemsFromModelItem(item: NodalSupport | null): TableNode[] {
      if (!item) return []

      return [
        {
          name: this.$t('Name'),
          value: item.name,
        },
        {
          name: this.$t('Type'),
          value: item.typeName,
        },
        {
          name: 'x',
          value: this.dofToValue(item.release?.x),
          unit: this.IsDofNumeric(this.dofToValue(item.release?.x)) ? latex_units.kn_mm : undefined,
        },
        {
          name: 'y',
          value: this.dofToValue(item.release?.y),
          unit: this.IsDofNumeric(this.dofToValue(item.release?.y)) ? latex_units.kn_mm : undefined,
        },
        {
          name: 'z',
          value: this.dofToValue(item.release?.z),
          unit: this.IsDofNumeric(this.dofToValue(item.release?.z)) ? latex_units.kn_mm : undefined,
        },
        {
          name: 'RX',
          value: this.dofToValue(item.release?.rx),
          unit: this.IsDofNumeric(this.dofToValue(item.release?.rx))
            ? latex_units.knm_rad
            : undefined,
        },
        {
          name: 'RY',
          value: this.dofToValue(item.release?.ry),
          unit: this.IsDofNumeric(this.dofToValue(item.release?.ry))
            ? latex_units.knm_rad
            : undefined,
        },
        {
          name: 'RZ',
          value: this.dofToValue(item.release?.rz),
          unit: this.IsDofNumeric(this.dofToValue(item.release?.rz))
            ? latex_units.knm_rad
            : undefined,
        },
        {
          name: 'Rw',
          value: this.dofToValue(item.release?.rw),
          unit: this.IsDofNumeric(this.dofToValue(item.release?.rw))
            ? latex_units.knm2__rad_m
            : undefined,
        },
        {
          name: this.$t('Eccentricity reference'),
          value: `${item.eccentricity?.reference}`,
        },
        {
          name: this.$t('Eccentricity - y'),
          value: roundToTwoDigits(item.eccentricity?.y),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Eccentricity - z'),
          value: roundToTwoDigits(item.eccentricity?.z),
          unit: latex_units.mm,
        },
      ]
    },

    dofToValue(dof: Dof | undefined): string | number | undefined {
      if (!dof) return undefined

      if (
        dof.type === this.$t('Fix') ||
        dof.type === this.$t('Free') ||
        dof.type === this.$t('Continous')
      ) {
        return dof.type
      }

      return roundToTwoDigits(dof.value)
    },
    IsDofNumeric(dof: string | number | undefined): boolean {
      if (typeof dof === 'number') {
        return true
      }

      return false
    },
  },
})
