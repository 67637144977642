var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "cs-simple-dialog",
        {
          attrs: {
            value: _vm.value,
            title: _vm.$t("Beszerzési szabályok"),
            width: 600,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("input", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "cs-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { outlined: "", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("input", false)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                  ),
                  _c(
                    "cs-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.saveBtnDisabled,
                      },
                      on: { click: _vm.handleSaveClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _vm.loading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _c("div", { staticClass: "supply-settings-dialog-subtitle" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("Járatos szelvényhosszak felvétele")) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mt-1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `Lehetőség van általánosan meghatározni a beszerzéskor szükséges járatos szelvényhosszak mértékét miliméterben. Az itt megadott mértékek automatikusan bekerülnek szelvényekhez rendelődve a beszerzési folyamat könnyítése érdekében. Tetszőleges szelvényhosszak írhatóak, egyes hossz mértékek vesszővel választhatóak el egymástól.`
                      )
                    ) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "mt-6" },
                [
                  _c("cs-tag-area", {
                    staticClass: "supply-settings-dialog-tag-area",
                    attrs: {
                      placeholder: _vm.$t(
                        "Írj be tetszőleges szelvényhosszakat..."
                      ),
                      label: _vm.$t("Járatos szelvényhosszak (mm)"),
                      persistentPlaceholder: "",
                    },
                    model: {
                      value: _vm.defaultLengths,
                      callback: function ($$v) {
                        _vm.defaultLengths = $$v
                      },
                      expression: "defaultLengths",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "supply-settings-dialog-subtitle mt-8" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("Maximális veszteség felvétele")) + " "
                  ),
                ]
              ),
              _c("div", { staticClass: "mt-1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `Lehetőség van általánosan meghatározni a beszerzéskor megengedett maximális veszteség mértékét.`
                      )
                    ) +
                    " "
                ),
              ]),
              _c("cs-unit-field", {
                staticClass: "mt-4",
                attrs: {
                  title: _vm.$t("Maximális veszteség:"),
                  rules: _vm.wastePercentageRules,
                  unit: "\\%",
                  precision: 0,
                },
                model: {
                  value: _vm.wastePercentage,
                  callback: function ($$v) {
                    _vm.wastePercentage = $$v
                  },
                  expression: "wastePercentage",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }