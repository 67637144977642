var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-grid",
    {
      staticClass: "share-user-row",
      attrs: {
        "template-columns": _vm.templateColumns,
        "fill-width": "",
        justify: "stretch",
        gap: _vm.columnGap,
      },
    },
    [
      _c("cs-avatar", {
        attrs: {
          empty: !_vm.share.user || !_vm.share.user.id,
          email: _vm.share.user.email,
        },
      }),
      _c("span", [_vm._v(_vm._s(_vm.share.user.email))]),
      _c("cs-select", {
        attrs: {
          solo: "",
          flat: "",
          dense: "",
          "hide-details": "",
          "append-icon": "mdi-chevron-down",
          "menu-props": { bottom: true, offsetY: true },
          items: _vm.platforms,
          "item-text": "displayText",
          "item-value": "value",
          outlined: "",
        },
        model: {
          value: _vm.sharePlatform,
          callback: function ($$v) {
            _vm.sharePlatform = $$v
          },
          expression: "sharePlatform",
        },
      }),
      _c("cs-select", {
        attrs: {
          solo: "",
          flat: "",
          dense: "",
          "hide-details": "",
          "append-icon": "mdi-chevron-down",
          "menu-props": { bottom: true, offsetY: true },
          items: _vm.types,
          "item-text": "displayText",
          "item-value": "value",
          outlined: "",
        },
        model: {
          value: _vm.shareType,
          callback: function ($$v) {
            _vm.shareType = $$v
          },
          expression: "shareType",
        },
      }),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _vm.changed
            ? _c(
                "cs-btn",
                {
                  attrs: {
                    icon: "",
                    loading: _vm.isThisLoading,
                    disabled: !!_vm.currentlyLoading,
                    minWidth: "0",
                  },
                  on: { click: _vm.onModify },
                },
                [_c("v-icon", [_vm._v("mdi-content-save-outline")])],
                1
              )
            : _c(
                "cs-btn",
                {
                  attrs: {
                    icon: "",
                    loading: _vm.isThisLoading,
                    disabled: !!_vm.currentlyLoading,
                    minWidth: "0",
                  },
                  on: { click: _vm.onDelete },
                },
                [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }