
import { Get, StructuralMember } from '@consteel/storm'
import Vue from 'vue'
import { latex_units } from '../../../services'
import {
  onStockStore,
  OnStockWarehouseOptimization,
  OnStockObjectMatch,
  OnStockWarehouseSummaryItem,
} from '@/modules/onstock'
import { modelViewerStore } from '@/store/modelViewer.store'
import InfoTable from './InfoTable.vue'
import { TableNode } from './TableNode'

export default Vue.extend({
  name: 'WarehouseOptimizationSectionTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getTableItemsFromWarehouseSummaryItem(
      summaryItemBySection?: OnStockWarehouseSummaryItem,
      summaryItemByMaterial?: OnStockWarehouseSummaryItem
    ): TableNode[] {
      if (!summaryItemBySection || !summaryItemByMaterial) return []

      return [
        {
          name: this.$t('Szelvény'),
          value: summaryItemBySection.name,
          children: [
            {
              name: this.$t('Össz db'),
              value: summaryItemBySection.quantity,
              unit: 'db',
            },
            {
              name: this.$t('Össz hossz'),
              value: summaryItemBySection.length,
              unit: latex_units.mm,
            },
            // {
            //   name: this.$t('Felhasznált db'),
            //   value: undefined,
            // },
            // {
            //   name: this.$t('Felhasznált hossz'),
            //   value: undefined,
            // },
            {
              name: this.$t('Elemcsoportok'),
              value: ' ',
              children: summaryItemBySection.warehouseSummaryItemBlocks.map((itemBlock) => ({
                name: itemBlock.length.toString() + ' mm',
                value: itemBlock.quantity,
                unit: 'db',
              })),
            },
          ],
        },
        {
          name: this.$t('Anyag'),
          value: summaryItemByMaterial.name,
          children: [
            {
              name: this.$t('Össz db'),
              value: summaryItemByMaterial.quantity,
              unit: 'db',
            },
            {
              name: this.$t('Össz hossz'),
              value: summaryItemByMaterial.length,
              unit: latex_units.mm,
            },
            // {
            //   name: this.$t('Felhasznált db'),
            //   value: undefined,
            // },
            // {
            //   name: this.$t('Felhasznált hossz'),
            //   value: undefined,
            // },
            {
              name: this.$t('Elemcsoportok'),
              value: ' ',
              children: summaryItemByMaterial.warehouseSummaryItemBlocks.map((itemBlock) => ({
                name: itemBlock.length.toString() + ' mm',
                value: itemBlock.quantity,
                unit: 'db',
              })),
            },
          ],
        },
      ]
    },
    getOnStockWarehouseSummaryItemByMaterial(
      warehouseOptimization: OnStockWarehouseOptimization
    ): OnStockWarehouseSummaryItem | undefined {
      const selectedOnStockObjectMatch = this.getSelectedOnStockObjectMatch(warehouseOptimization)

      return warehouseOptimization.warehouseSummaryItems.find(
        (summaryItem) =>
          summaryItem.name == selectedOnStockObjectMatch?.warehouseObject.materialName
      )
    },
    getOnStockWarehouseSummaryItemBySection(
      warehouseOptimization: OnStockWarehouseOptimization
    ): OnStockWarehouseSummaryItem | undefined {
      const selectedOnStockObjectMatch = this.getSelectedOnStockObjectMatch(warehouseOptimization)
      return warehouseOptimization.warehouseSummaryItems.find(
        (summaryItem) => summaryItem.name == selectedOnStockObjectMatch?.warehouseObject.sectionName
      )
    },
    getSelectedOnStockObjectMatch(
      warehouseOptimization: OnStockWarehouseOptimization
    ): OnStockObjectMatch | undefined {
      const selectedSmadsteelObjectId = modelViewerStore.selectedIds[0]

      return warehouseOptimization.warehouseObjectMatches.find(
        (match) => match.smadsteelId == selectedSmadsteelObjectId
      )
    },
  },
  computed: {
    primaryItems(): TableNode[] {
      return this.getTableItemsFromWarehouseSummaryItem(
        this.primaryOnStockWarehouseSummaryItemBySection,
        this.primaryOnStockWarehouseSummaryItemByMaterial
      )
    },
    secondaryItems(): TableNode[] {
      return this.getTableItemsFromWarehouseSummaryItem(
        this.secondaryOnStockWarehouseSummaryItemBySection,
        this.secondaryOnStockWarehouseSummaryItemByMaterial
      )
    },
    primaryStructuralMember(): StructuralMember | null {
      if (!modelViewerStore.model.rawSmadsteelModel) return null

      const member = Get<StructuralMember>(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        StructuralMember
      )
      return member
    },
    secondaryStructuralMember(): StructuralMember | null {
      if (!modelViewerStore.secondaryModel?.rawSmadsteelModel) return null

      const member = Get<StructuralMember>(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        StructuralMember
      )
      return member
    },
    primaryOnStockWarehouseSummaryItemByMaterial(): OnStockWarehouseSummaryItem | undefined {
      if (!onStockStore.warehouseOptimization) return

      return this.getOnStockWarehouseSummaryItemByMaterial(onStockStore.warehouseOptimization)
    },
    primaryOnStockWarehouseSummaryItemBySection(): OnStockWarehouseSummaryItem | undefined {
      if (!onStockStore.warehouseOptimization) return

      return this.getOnStockWarehouseSummaryItemBySection(onStockStore.warehouseOptimization)
    },
    secondaryOnStockWarehouseSummaryItemByMaterial(): OnStockWarehouseSummaryItem | undefined {
      if (!onStockStore.warehouseOptimizationSecondary) return

      return this.getOnStockWarehouseSummaryItemByMaterial(
        onStockStore.warehouseOptimizationSecondary
      )
    },
    secondaryOnStockWarehouseSummaryItemBySection(): OnStockWarehouseSummaryItem | undefined {
      if (!onStockStore.warehouseOptimizationSecondary) return

      return this.getOnStockWarehouseSummaryItemBySection(
        onStockStore.warehouseOptimizationSecondary
      )
    },
  },
})
