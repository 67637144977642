
import { modelViewerStore } from '@/store/modelViewer.store'
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'
import {
  InfoTable,
  LineLoadTable,
  LoadTransferSurfaceTable,
  NodalLoadTable,
  StructuralMemberTable,
  SectionTable,
  LoadCaseTable,
  StructuralPlateTable,
  SupportPointTable,
  SurfaceLoadTable,
  WarehouseOptimizationSectionTable,
  HaunchedStructuralMemberTable,
} from './Info'
import ModelInformation from './Info/ModelInformation.vue'
import { StructuralViewModel } from '@consteel/straw'
import { onStockStore } from '@/modules/onstock'
import WarehouseOptimizationItemGroupTable from './Info/WarehouseOptimizationItemGroupTable.vue'

export default Vue.extend({
  name: 'InfoTab',
  components: {
    CsFlex,
    ModelInformation,
    InfoTable,
    SectionTable,
    LoadCaseTable,
    LineLoadTable,
    WarehouseOptimizationSectionTable,
    LoadTransferSurfaceTable,
    NodalLoadTable,
    StructuralMemberTable,
    StructuralPlateTable,
    SupportPointTable,
    SurfaceLoadTable,
    WarehouseOptimizationItemGroupTable,
    HaunchedStructuralMemberTable,
  },
  computed: {
    onlyOneSelected(): boolean {
      return modelViewerStore.selectedIds.length === 1
    },
    moreThanOneItemSelected(): boolean {
      return modelViewerStore.selectedIds.length > 1
    },
    showWarehouseOptimizationItemGroupTable(): boolean {
      if (!onStockStore.warehouseOptimization || !modelViewerStore.selectedIds.length) return false

      const selectedOnStockObjectMatches = modelViewerStore.selectedIds.map((id) => {
        return onStockStore.warehouseOptimization!.warehouseObjectMatches.find(
          (match) => match.smadsteelId == id
        )
      })

      const haveTheSameBarcode = selectedOnStockObjectMatches.reduce(
        ({ first, lastBarcode, sameBarcode }, objectMatch) => {
          if (!sameBarcode) return { first, lastBarcode, sameBarcode }

          const relevantBarcode = objectMatch?.warehouseObject.barcode
          if (first) {
            return {
              lastBarcode: relevantBarcode,
              first: false,
              sameBarcode: relevantBarcode != undefined,
            }
          } else {
            return {
              lastBarcode: relevantBarcode,
              first: false,
              sameBarcode: sameBarcode && relevantBarcode == lastBarcode,
            }
          }
        },
        { lastBarcode: undefined as undefined | number, sameBarcode: true, first: true }
      ).sameBarcode

      return haveTheSameBarcode
    },
    showWarehouseOptimizationSectionTable(): boolean {
      if (!onStockStore.warehouseOptimization || !modelViewerStore.selectedIds.length) return false

      const selectedOnStockObjectMatches = modelViewerStore.selectedIds.map((id) => {
        return onStockStore.warehouseOptimization!.warehouseObjectMatches.find(
          (match) => match.smadsteelId == id
        )
      })

      const haveTheSameParent = selectedOnStockObjectMatches.reduce(
        ({ first, lastMaterialName, lastSectionName, sameParent }, objectMatch) => {
          if (!sameParent) return { first, lastMaterialName, lastSectionName, sameParent }

          const summaryItemNameByMaterial =
            onStockStore.warehouseOptimization!.warehouseSummaryItems.find(
              (summaryItem) => summaryItem.name == objectMatch?.warehouseObject.materialName
            )?.name || ''

          const summaryItemNameBySection =
            onStockStore.warehouseOptimization!.warehouseSummaryItems.find(
              (summaryItem) => summaryItem.name == objectMatch?.warehouseObject.sectionName
            )?.name || ''

          if (first) {
            return {
              lastMaterialName: summaryItemNameByMaterial,
              lastSectionName: summaryItemNameBySection,
              first: false,
              sameParent: !!summaryItemNameByMaterial && !!summaryItemNameBySection,
            }
          } else {
            const sameMaterialName = lastMaterialName == summaryItemNameByMaterial
            const sameSectionName = lastSectionName == summaryItemNameBySection
            return {
              lastMaterialName: summaryItemNameByMaterial,
              lastSectionName: summaryItemNameBySection,
              first: false,
              sameParent: sameParent && sameMaterialName && sameSectionName,
            }
          }
        },
        { lastMaterialName: '', lastSectionName: '', sameParent: true, first: true }
      ).sameParent

      return haveTheSameParent
    },
    selectedItemsHaveTheSameParent(): boolean {
      if (!modelViewerStore.model.viewModel) return false
      const firstlySelectedItemSectionId = this.getSectionOfSelectedItem(
        modelViewerStore.model.viewModel,
        modelViewerStore.selectedIds[0]
      )

      for (let i = 1; i < modelViewerStore.selectedIds.length; i++) {
        let currentItemId = this.getSectionOfSelectedItem(
          modelViewerStore.model.viewModel,
          modelViewerStore.selectedIds[i]
        )
        if (currentItemId !== firstlySelectedItemSectionId) {
          return false
        }
      }
      return true
    },
    showSectionTable(): boolean {
      return this.selectedItemsHaveTheSameParent && this.moreThanOneItemSelected
    },
    showLoadTable(): string | undefined {
      if (!modelViewerStore.activeLoadId || !modelViewerStore.model.viewModel) return undefined
      return 'LoadCaseTable'
    },
    currentSelectionTypeName(): string | undefined {
      if (!this.onlyOneSelected || !modelViewerStore.model.viewModel) return undefined
      //megkeressük az első

      let selectionTypeName = this.getSelectedItemTypeName(modelViewerStore.model.viewModel)

      //ha nincs benne az elsőbe és van második modellünk, akkor megnézzük abban is
      if (modelViewerStore.secondaryModel?.viewModel && !selectionTypeName) {
        selectionTypeName = this.getSelectedItemTypeName(modelViewerStore.secondaryModel.viewModel)
      }

      return selectionTypeName
    },
    currentComponentName(): string | undefined {
      if (
        !this.currentSelectionTypeName ||
        !this.$options.components?.[`${this.currentSelectionTypeName}Table`]
      ) {
        console.warn(`${this.currentSelectionTypeName}Table not found`)
        return undefined
      }

      return `${this.currentSelectionTypeName}Table`
    },
    compare(): boolean {
      return !!(
        modelViewerStore.model.rawSmadsteelModel &&
        modelViewerStore.secondaryModel.rawSmadsteelModel
      )
    },
  },
  methods: {
    getSelectedItemTypeName(viewModel: StructuralViewModel): string | undefined {
      return viewModel?.objects.find((obj) => obj.id === modelViewerStore.selectedIds[0])?.typeName
    },
    getSectionOfSelectedItem(
      viewModel: StructuralViewModel,
      selectedId: string
    ): string | undefined {
      return viewModel?.structuralMembers?.find((obj) => obj.id === selectedId)?.section.id
    },
  },
})
