var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "floating-tabs",
      attrs: {
        fillWidth: "",
        vertical: "",
        justify: "flex-start",
        align: "flex-start",
      },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "tab-header",
          attrs: { fillWidth: "", justify: "flex-start" },
        },
        [
          _c("cs-flex", { staticClass: "tab-header-before" }, [
            _c(
              "a",
              {
                staticClass: "logo-link",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/explorer")
                  },
                },
              },
              [_c("on-stock-logo-svg", { staticClass: "ml-3 mr-3 logo" })],
              1
            ),
          ]),
          _vm._l(_vm.tabItems, function (item, index) {
            return _c(
              "cs-flex",
              {
                key: index,
                class: [
                  { active: index === _vm.activeMainTab },
                  "floating-tab-item",
                  "py-2",
                  "px-5",
                ],
                attrs: { vertical: "" },
                on: { click: item.onClick },
              },
              [
                _c(item.icon, {
                  tag: "component",
                  staticClass: "floating-tab-icon mb-1",
                }),
                _c("span", [_vm._v(_vm._s(item.text))]),
              ],
              1
            )
          }),
          _c(
            "cs-flex",
            { staticClass: "folder-breadcrumbs" },
            [_c("cs-breadcrumbs", { attrs: { items: _vm.breadcrumbsItems } })],
            1
          ),
          _c(
            "cs-flex",
            { staticClass: "tab-header-after" },
            [
              _c(
                "cs-flex",
                {
                  staticClass: "tab-header-after mr-8",
                  attrs: { gap: "2rem" },
                },
                [
                  _c("change-theme-button"),
                  _c("warehouse-optimization-status-changer"),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "div",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "tab-button",
                                    {
                                      attrs: {
                                        disabled: _vm.disableSettings,
                                        text: _vm.$t("Beállítások"),
                                        active: _vm.isActiveSettings,
                                      },
                                    },
                                    [_c("settings-svg")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            {
                              model: {
                                value: _vm.setting,
                                callback: function ($$v) {
                                  _vm.setting = $$v
                                },
                                expression: "setting",
                              },
                            },
                            _vm._l(_vm.settingsItems, function (item, index) {
                              return _c(
                                "v-list-item",
                                { key: index, on: { click: item.click } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.text)),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "tab-button",
                    {
                      attrs: {
                        text: _vm.$t("Info"),
                        active: _vm.activeSideTab === 1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("changeSideTab", 1)
                        },
                      },
                    },
                    [_c("info-svg")],
                    1
                  ),
                  _c("toolbar-menu"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("engineer-rules-dialog", {
        model: {
          value: _vm.showEngineerRulesDialog,
          callback: function ($$v) {
            _vm.showEngineerRulesDialog = $$v
          },
          expression: "showEngineerRulesDialog",
        },
      }),
      _c("manager-rules-summary-dialog", {
        model: {
          value: _vm.showManagerRulesSummaryDialog,
          callback: function ($$v) {
            _vm.showManagerRulesSummaryDialog = $$v
          },
          expression: "showManagerRulesSummaryDialog",
        },
      }),
      _c("manage-collaborators-dialog", {
        attrs: {
          collaborators: _vm.project?.collaborators,
          projectId: _vm.project?.id,
          currentProject: _vm.project,
        },
        model: {
          value: _vm.showShareModal,
          callback: function ($$v) {
            _vm.showShareModal = $$v
          },
          expression: "showShareModal",
        },
      }),
      _c("supply-settings-dialog", {
        model: {
          value: _vm.showSupplySettingsDialog,
          callback: function ($$v) {
            _vm.showSupplySettingsDialog = $$v
          },
          expression: "showSupplySettingsDialog",
        },
      }),
      _c("v-divider", {
        staticClass: "tab-separator",
        attrs: { vertical: false },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }