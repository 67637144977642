var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      class: [
        {
          active: _vm.active,
          disabled: _vm.disabled,
          closeable: _vm.closeable,
        },
        "tab-button",
      ],
      attrs: { vertical: "", "no-content-stretch": "" },
      on: { click: _vm.click },
    },
    [
      _c("div", { staticClass: "tab-button-icon" }, [_vm._t("default")], 2),
      _c("span", [_vm._v(_vm._s(_vm.text))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }