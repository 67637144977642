
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'
import { VueConstructor } from 'vue/types'

export interface IFloatingTabItemProps {
  icon: VueConstructor<Vue>
  text: string
}

export default Vue.extend({
  name: 'TabButton',
  components: { CsFlex },
  props: {
    text: {
      required: true,
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    closeable: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
  },
})
