var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "model-information",
      class: { open: _vm.open },
      attrs: { vertical: "", "no-content-stretch": "" },
    },
    [
      _c(
        "cs-flex",
        {
          class: ["model-information-header", "pl-3", "py-3"],
          attrs: { fillWidth: "", justify: "flex-start" },
          on: {
            click: function ($event) {
              _vm.open = !_vm.open
            },
          },
        },
        [
          _c("chevron-down-svg", { staticClass: "mr-3" }),
          _c("span", [_vm._v(_vm._s(_vm.$t("Model information")))]),
        ],
        1
      ),
      _c("div", { staticClass: "model-information-separator closeable" }),
      _vm.open
        ? _c(
            "cs-flex",
            {
              staticClass: "model-information-content pa-3",
              attrs: { "fill-width": "", gap: "0.75rem" },
            },
            [
              _c("span", { staticClass: "model-information-label" }, [
                _vm._v(_vm._s(_vm.$t("Steel bar members"))),
              ]),
              _c("model-information-card", {
                attrs: {
                  compare: _vm.compare,
                  changed: _vm.numberOfBarsChanged,
                  value: _vm.numberOfBars,
                  valueSecondary: _vm.numberOfBarsSecondary,
                  label: _vm.$t("Number of bars"),
                  modelNumber: _vm.primaryModelNumber,
                  modelNumberSecondary: _vm.secondaryModelNumber,
                },
              }),
              _c("model-information-card", {
                attrs: {
                  compare: _vm.compare,
                  changed: _vm.totalWeightChanged,
                  value: _vm.totalWeight,
                  unit: _vm.weightUnit,
                  valueSecondary: _vm.totalWeightSecondary,
                  label: _vm.$t("Total weight"),
                  modelNumber: _vm.primaryModelNumber,
                  modelNumberSecondary: _vm.secondaryModelNumber,
                },
              }),
              _c("model-information-card", {
                attrs: {
                  compare: _vm.compare,
                  changed: _vm.totalSurfaceChanged,
                  value: _vm.totalSurface,
                  unit: _vm.surfaceUnit,
                  valueSecondary: _vm.totalSurfaceSecondary,
                  label: _vm.$t("Total surface"),
                  modelNumber: _vm.primaryModelNumber,
                  modelNumberSecondary: _vm.secondaryModelNumber,
                },
              }),
              _c("model-information-card", {
                attrs: {
                  compare: _vm.compare,
                  changed: _vm.totalLengthChanged,
                  value: _vm.totalLength,
                  unit: _vm.lengthUnit,
                  valueSecondary: _vm.totalLengthSecondary,
                  label: _vm.$t("Total length"),
                  modelNumber: _vm.primaryModelNumber,
                  modelNumberSecondary: _vm.secondaryModelNumber,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "model-information-separator" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }