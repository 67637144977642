
import { modelViewerStore } from '@/store/modelViewer.store'
import { Get, LoadCase } from '@consteel/storm'
import Vue from 'vue'
import InfoTable from './InfoTable.vue'
import { TableNode } from './TableNode'
export default Vue.extend({
  name: 'LoadCaseTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryMember(): LoadCase | null {
      if (!modelViewerStore.model.rawSmadsteelModel) return null
      const loadCase = Get<LoadCase>(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.activeLoadId,
        LoadCase
      )
      return loadCase
    },
    secondaryMember(): LoadCase | null {
      if (!modelViewerStore.secondaryModel.rawSmadsteelModel || !this.compare) return null
      return Get<LoadCase>(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        modelViewerStore.activeLoadId,
        LoadCase
      )
    },
    primaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.primaryMember)
    },
    secondaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.secondaryMember)
    },
  },
  methods: {
    getTableItemsFromModelItem(item: LoadCase | null): TableNode[] {
      if (!item) return []
      return [
        {
          name: this.$t('Name'),
          value: item.name,
        },
        {
          name: this.$t('Load Group'),
          value: item.loadGroup?.name,
          children: [
            {
              name: this.$t('Type'),
              value: item.loadGroup?.type,
            },
            {
              name: this.$t('Safety Factor'),
              value: String(item.loadGroup?.safetyFactor),
            },
            {
              name: this.$t('Combination Factor'),
              value: String(item.loadGroup?.combinationFactor),
            },
            {
              name: this.$t('Frequent Load Level Factor'),
              value: String(item.loadGroup?.frequentLoadLevelFactor),
            },
            {
              name: this.$t('Quasi Permament Load Level Factor'),
              value: String(item.loadGroup?.quasiPermanentLoadLevelFactor),
            },
          ],
        },
        {
          name: this.$t('Favourable'),
          value: String(item.favourable),
        },
        {
          name: this.$t('NotMainInTransient'),
          value: String(item.notMainInTransientCombination),
        },
        {
          name: this.$t('NotMainInAccidental'),
          value: String(item.notMainInAccidentalCombination),
        },
      ]
    },
  },
})
