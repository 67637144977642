
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { modelViewerStore } from '@/store/modelViewer.store'
import { Get, LineLoad } from '@consteel/storm'
import Vue from 'vue'
import InfoTable from './InfoTable.vue'
import { TableNode } from './TableNode'

export default Vue.extend({
  name: 'LineLoadTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryMember(): LineLoad | null {
      if (!modelViewerStore.model.rawSmadsteelModel) return null

      return Get<LineLoad>(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        LineLoad
      )
    },
    secondaryMember(): LineLoad | null {
      if (!this.compare || !modelViewerStore.secondaryModel.rawSmadsteelModel) return null
      return Get<LineLoad>(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        LineLoad
      )
    },
    primaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.primaryMember)
    },

    secondaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.secondaryMember)
    },
  },
  methods: {
    multiplicationFactor(member: LineLoad | null): number {
      return member?.multiplicationFactor || 1
    },
    getTableItemsFromModelItem(item: LineLoad | null): TableNode[] {
      if (!item) return []

      return [
        {
          name: this.$t('Name'),
          value: item.name,
        },
        {
          name: this.$t('Loadcase name'),
          value: item.loadCase?.name,
        },
        {
          name: this.$t('X1'),
          value: roundToTwoDigits(
            item.force1 ? item?.force1?.x * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: this.$t('Y1'),
          value: roundToTwoDigits(
            item.force1 ? item?.force1?.y * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: this.$t('Z1'),
          value: roundToTwoDigits(
            item.force1 ? item?.force1?.z * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: this.$t('X2'),
          value: roundToTwoDigits(
            item.force2 ? item?.force2?.x * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: this.$t('Y2'),
          value: roundToTwoDigits(
            item.force2 ? item?.force2?.y * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: this.$t('Z2'),
          value: roundToTwoDigits(
            item.force2 ? item?.force2?.z * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: this.$t('Eccentricity reference'),
          value: `${item?.eccentricity?.reference}`,
        },
        {
          name: this.$t('Eccentricity - y'),
          value: roundToTwoDigits(item?.eccentricity?.y),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Eccentricity - z'),
          value: roundToTwoDigits(item?.eccentricity?.z),
          unit: latex_units.mm,
        },
      ]
    },
  },
})
